

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    height: 100%;
  }

  data-styled.g1[id="sc-global-iwunXs1"] {
    content: "sc-global-iwunXs1,"
  }

  .boUWED {
    margin: 0;
    font-family: Confiauto Sans;
    font-weight: normal;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    color: #131313;
    font-size: 62px;
    line-height: 72px;
  }

  @media (min-width:600px) and (max-width:959px) {
    .boUWED {
      font-size: 42px;
      line-height: 64px;
    }

    
  }

  @media (max-width:599px) {
    .boUWED {
      font-size: 36px;
      line-height: 40px;
    }
 
  }

  data-styled.g2[id="sc-bdvvtL"] {
    content: "boUWED,"
  }

  data-styled.g3[id="sc-gsDKAQ"] {
    content: "gsoPfm,"
  }

  .dnYEtG {
    margin: 0;
    font-family: Confiauto Sans;
    font-weight: normal;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    color: #131313;
    font-size: 36px;
    line-height: 40px;
  }

  data-styled.g4[id="sc-dkPtRN"] {
    content: "dnYEtG,"
  }

  .dyesrI {
    font-family: Calibre;
    font-weight: 600;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
    color: #131313;
    font-size: 20px;
    line-height: 32px;
  }

  data-styled.g8[id="sc-gKclnd"] {
    content: "dyesrI,"
  }

  .gTDDhu {
    font-family: Calibre;
    font-weight: normal;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
    color: #131313;
    font-size: 18px;
    line-height: 24px;
  }

  data-styled.g9[id="sc-iCfMLu"] {
    content: "gTDDhu,"
  }

  .dJRzsx {
    font-family: Calibre;
    font-weight: normal;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
    color: #131313;
    font-size: 14px;
    line-height: 20px;
  }

  data-styled.g10[id="sc-furwcr"] {
    content: "dJRzsx,dRtLXI,"
  }

  .gXA-dde {
    min-height: 20px;
    max-height: 20px;
    min-width: 116px;
    max-width: 116px;
  }

  .citUwy {
    min-height: 16px;
    max-height: 16px;
    min-width: 18px;
    max-width: 18px;
  }

  .hPEbqG {
    min-height: 8px;
    max-height: 8px;
    min-width: 12px;
    max-width: 12px;
  }

  .fRgtOG {
    min-height: 18px;
    max-height: 18px;
    min-width: 18px;
    max-width: 18px;
  }

  data-styled.g12[id="sc-jrQzAO"] {
    content: "gXA-dde,citUwy,hPEbqG,fRgtOG,"
  }

  .bCRnWf {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 4px;
  }

  data-styled.g16[id="sc-egiyK"] {
    content: "bCRnWf,"
  }

  data-styled.g19[id="sc-hBUSln"] {
    content: "bFdfPe,"
  }

  .eUBogc {
    min-height: 48px;
    max-height: 48px;
    padding: 0 32px;
    border: none;
    cursor: pointer;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-family: Calibre;
    -webkit-letter-spacing: 1.25px;
    -moz-letter-spacing: 1.25px;
    -ms-letter-spacing: 1.25px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    white-space: nowrap;
    background: #D6D7DA;
    color: #999DA3;
    cursor: default;
  }

  data-styled.g20[id="sc-fotOHu"] {
    content: "eUBogc,"
  }

  .UpVmF {
    min-height: 48px;
    max-height: 48px;
    padding: 0 32px;
    border: none;
    cursor: pointer;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-family: Calibre;
    -webkit-letter-spacing: 1.25px;
    -moz-letter-spacing: 1.25px;
    -ms-letter-spacing: 1.25px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    white-space: nowrap;
    background: #FFFFFF;
    color: #941b1c;
  }

  .UpVmF:hover {
    background: #1313131A;
  }

  .UpVmF:focus-visible {
    outline: 2px solid #1960D0;
  }

  data-styled.g22[id="sc-bkkeKt"] {
    content: "UpVmF,"
  }

  .ktrmkx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
  }

  data-styled.g34[id="sc-giYglK"] {
    content: "ktrmkx,"
  }

  .iZiwmQ {
    position: relative;
  }

  data-styled.g38[id="sc-ikJyIC"] {
    content: "iZiwmQ,"
  }

  .jjlIvY {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #131313;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    font-size: 18px;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
    font-family: Calibre;
    outline: none;
    border: 1px solid #999DA3;
  }

  .jjlIvY:disabled {
    color: #999DA3;
    background: #F5F5F5;
  }

  .jjlIvY:focus {
    outline: 2px solid #1960D0;
  }

  .jjlIvY:before {
    content: 'X';
  }

  data-styled.g39[id="sc-jJoQJp"] {
    content: "jjlIvY,"
  }

  .byPIxE {
    font-family: Calibre;
    position: relative;
  }

  data-styled.g41[id="sc-gWXbKe"] {
    content: "byPIxE,"
  }

  .ldpJgY {
    position: relative;
  }

  data-styled.g42[id="sc-cCcXHH"] {
    content: "ldpJgY,"
  }

  .fAyAZK {
    background-color: #FFFFFF;
    cursor: pointer;
    max-height: 242px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
  }

  data-styled.g44[id="sc-jcFjpl"] {
    content: "fAyAZK,"
  }

  .kRznVL {
    -webkit-transition: -webkit-transform 0.3s;
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
  }

  data-styled.g49[id="sc-cTAqQK"] {
    content: "kRznVL,"
  }

  .bdShXQ {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #FFFFFF;
    border: 1px solid #999DA3;
    color: #131313;
    cursor: pointer;
    font-family: Calibre;
    font-size: 18px;
    height: 48px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 1rem;
    position: relative;
    width: 100%;
    color: #6C717A;
  }

  .bdShXQ:focus {
    outline: solid 2px #1960D0;
  }

  .bdShXQ[disabled] {
    background-color: #F5F5F5;
    cursor: default;
  }

  .bdShXQ[disabled] .sc-efQSVx {
    opacity: 0.25;
  }

  data-styled.g50[id="sc-jObWnj"] {
    content: "bdShXQ,"
  }

  .dJxooj {
    margin-right: auto;
  }

  data-styled.g51[id="sc-dPiLbb"] {
    content: "dJxooj,"
  }

  .cHazxn {
    border-top: none;
    top: -1px !important;
  }

  data-styled.g53[id="sc-cNKqjZ"] {
    content: "cHazxn,"
  }

  .bqGgld {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 48px;
    max-height: 48px;
    padding: 0 32px;
    border: none;
    cursor: pointer;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-family: Calibre;
    -webkit-letter-spacing: 1.25px;
    -moz-letter-spacing: 1.25px;
    -ms-letter-spacing: 1.25px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    white-space: nowrap;
    background: #FFFFFF;
    color: #941b1c;
    border: solid 2px #941b1c;
  }

  .bqGgld:focus-visible {
    outline: 2px solid #1960D0;
  }

  .bqGgld:hover {
    background: #941b1c1A;
  }

  data-styled.g69[id="sc-faUpoM"] {
    content: "bqGgld,"
  }

  .dpogyX {
    font-family: Calibre;
    color: #131313;
    font-size: 18px;
    line-height: 24px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    font-weight: normal;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
  }

  .dpogyX:focus-visible {
    outline: 2px solid #1960D0;
  }

  data-styled.g71[id="sc-fWCJzd"] {
    content: "dpogyX,"
  }

  data-styled.g72[id="sc-dvQaRk"] {
    content: "jcGXgw,"
  }

  .faTzop {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }

  data-styled.g102[id="sc-uojGG"] {
    content: "faTzop,"
  }

  .hjVovA {
    font-family: Calibre;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    -webkit-letter-spacing: 1.25px;
    -moz-letter-spacing: 1.25px;
    -ms-letter-spacing: 1.25px;
    letter-spacing: 1.25px;
    color: #131313;
  }

  data-styled.g103[id="sc-bilyIR"] {
    content: "hjVovA,"
  }

  .erLwPf {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    background: none;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .erLwPf>*:not(:first-child) {
    margin-left: 8px;
  }

  .erLwPf .sc-bilyIR:after {
    display: block;
    content: '';
    border-bottom: solid 2px #941b1c;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform 250ms ease-in-out;
    -webkit-transition: transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out;
  }

  .erLwPf:hover .sc-bilyIR:after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }

  data-styled.g104[id="sc-eGPXGI"] {
    content: "erLwPf,"
  }

  .cnzQha {
    margin-left: 8px;
  }

  data-styled.g106[id="sc-kYHfwS"] {
    content: "cnzQha,"
  }

  .ibDPcO {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .ibDPcO>*:not(:first-child) {
    margin-left: 32px;
  }

  data-styled.g111[id="sc-fXEqDS"] {
    content: "ibDPcO,"
  }

  data-styled.g114[id="sc-eFegNN"] {
    content: "ifcjbQ,"
  }

  data-styled.g122[id="sc-gUQvok"] {
    content: "eMvhQw,"
  }

  .fOpFmZ {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: auto;
  }

  data-styled.g123[id="sc-fXeWAj"] {
    content: "fOpFmZ,"
  }

  .elXgWA {
    fill: #131313;
  }

  data-styled.g124[id="sc-fIosxK"] {
    content: "elXgWA,"
  }

  .hgPmDc {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
  }

  data-styled.g137[id="sc-cLpAjG"] {
    content: "hgPmDc,"
  }


  data-styled.g152[id="sc-cHzqoD"] {
    content: "aFaxi,"
  }

  .ieHePb {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: auto;
  }

  data-styled.g153[id="sc-JkixQ"] {
    content: "ieHePb,"
  }

  .cRGeEL {
    fill: #131313;
  }

  @media (max-width:599px) {
    .cRGeEL {
      width: 104px;
      min-width: 104px;
      max-width: 104px;
    }
  }

  @media (min-width:600px) and (max-width:959px) {
    .cRGeEL {
      width: 104px;
      min-width: 104px;
      max-width: 104px;
    }
  }

  data-styled.g154[id="sc-gGPzkF"] {
    content: "cRGeEL,"
  }

  .cKCdtp {
    -webkit-clip: rect(0 0 0 0);
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  }

  .cKCdtp:focus {
    background: white;
    -webkit-clip: auto;
    clip: auto;
    height: auto;
    left: 32px;
    position: fixed;
    top: 12px;
    width: auto;
    z-index: 1101;
  }

  .cKCdtp:-webkit-any-link:focus-visible {
    outline-offset: 0px;
  }

  data-styled.g161[id="sc-jlsrNB"] {
    content: "cKCdtp,"
  }

  .dppTtp {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  @media (max-width:599px) {
    .dppTtp {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }

  data-styled.g302[id="sc-cBIieI"] {
    content: "dppTtp,"
  }

  .LcYtZ {
    max-width: 400px;
    width: 100%;
  }

  @media (max-width:599px) {
    .LcYtZ {
      width: 100%;
      max-width: 100%;
      margin-bottom: 16px;
    }
  }

  data-styled.g303[id="sc-kqnjJL"] {
    content: "LcYtZ,"
  }

  .hOfgvZ {
    width: 148px;
  }

  @media (max-width:599px) {
    .hOfgvZ {
      width: 100%;
      max-width: 100%;
    }
  }

  data-styled.g304[id="sc-czvZiG"] {
    content: "hOfgvZ,"
  }

  .bBRXJO {
    background: #ffffff;
    width: 100%;
  }

  data-styled.g305[id="sc-jHwEXd"] {
    content: "bBRXJO,"
  }

  .gOiBQg {
    max-width: 1152px;
    padding: 96px 32px;
    background: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
    margin: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  @media (min-width:600px) and (max-width:959px) {
    .gOiBQg {
      padding: 64px 24px;
    }
  }

  @media (max-width:599px) {
    .gOiBQg {
      padding: 40px 16px 64px 16px;
    }
  }

  data-styled.g306[id="sc-bQFuvY"] {
    content: "gOiBQg,"
  }

  .jOSSJA {
    max-width: 528px;
    width: 60%;
  }

  @media (min-width:600px) and (max-width:959px) {
    .jOSSJA {
      width: 66%;
      margin-right: auto;
    }
  }

  @media (max-width:599px) {
    .jOSSJA {
      width: 59%;
      max-width: 300px;
      margin-right: auto;
    }
  }

  data-styled.g307[id="sc-jFkwbb"] {
    content: "jOSSJA,"
  }

  @media (min-width:600px) and (max-width:959px) {
    .kFfNtj {
      font-size: 48px;
      -webkit-letter-spacing: 1px;
      -moz-letter-spacing: 1px;
      -ms-letter-spacing: 1px;
      letter-spacing: 1px;
      line-height: 48px;
    }
  }

  @media (max-width:599px) {
    .kFfNtj {
      font-size: 40px;
      -webkit-letter-spacing: 1px;
      -moz-letter-spacing: 1px;
      -ms-letter-spacing: 1px;
      letter-spacing: 1px;
      line-height: 40px;
    }
  }

  data-styled.g308[id="sc-bOtlzW"] {
    content: "kFfNtj,"
  }

  .kYmqsf {
    padding-right: 6px;
  }

  data-styled.g309[id="sc-zjkyB"] {
    content: "kYmqsf,"
  }

  .dPCSBw {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 90%;
  }

  @media (min-width:600px) and (max-width:959px) {
    .dPCSBw {
      padding-top: 16px;
      max-width: 80%;
      display: inherit;
    }
  }

  @media (max-width:599px) {
    .dPCSBw {
      padding-top: 16px;
      max-width: 89%;
      display: inherit;
    }
  }

  data-styled.g310[id="sc-dXNJws"] {
    content: "dPCSBw,"
  }

  .cPOhWH {
    width: 40%;
  }

  @media (min-width:600px) and (max-width:959px) {
    .cPOhWH {
      width: 33%;
      padding-top: 16px;
    }
  }

  @media (max-width:599px) {
    .cPOhWH {
      width: 41%;
      padding-top: 16px;
    }
  }

  data-styled.g311[id="sc-hRMJXU"] {
    content: "cPOhWH,"
  }

  @media (max-width:599px) {
    .kCYWCk {
      max-width: 348px;
    }
  }

  data-styled.g312[id="sc-czWrlN"] {
    content: "kCYWCk,"
  }

  .bABSyx {
    color: #941b1c;
    white-space: nowrap;
  }

  data-styled.g313[id="sc-icMgfS"] {
    content: "bABSyx,"
  }

  .ficjEp {
    margin-top: -7%;
    width: 60%;
    padding-right: 60px;
  }

  @media (min-width:600px) and (max-width:959px) {
    .ficjEp {
      margin-top: -12%;
      width: 65%;
    }
  }

  @media (max-width:599px) {
    .ficjEp {
      width: 100%;
      padding-top: 32px;
      margin-top: auto;
      padding-right: 0px;
    }
  }

  data-styled.g314[id="sc-cKVNtL"] {
    content: "ficjEp,"
  }

  .itZKqc {
    padding-top: 8px;
  }

  data-styled.g315[id="sc-hgJWpk"] {
    content: "itZKqc,"
  }

  .dMWZGI {
    padding: 32px 64px;
  }

  @media (max-width:599px) {
    .dMWZGI {
      padding: 48px 16px;
    }
  }

  data-styled.g316[id="sc-bOTDDd"] {
    content: "dMWZGI,"
  }

  .gdDrAZ {
    margin-bottom: 32px;
  }

  data-styled.g317[id="sc-hrJsxi"] {
    content: "gdDrAZ,"
  }

  .fTHZSa {
    color: #941b1c;
    margin-bottom: 32px;
  }

  @media (max-width:599px) {
    .fTHZSa {
      margin: 16px;
      text-align: center;
      margin-top: 0;
    }
  }

  data-styled.g318[id="sc-bZSZLb"] {
    content: "fTHZSa,"
  }

  @media (max-width:599px) {
    .iVTuFx {
      margin-left: 0;
      margin-bottom: 32px;
      text-align: left;
    }
  }

  data-styled.g319[id="sc-jnSlpE"] {
    content: "iVTuFx,"
  }

  .itQDGr {
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }

  .itQDGr:hover {
    color: #941b1c;
  }

  data-styled.g320[id="sc-dWbSDx"] {
    content: "itQDGr,"
  }

  .hufnUu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: wrap;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    margin-bottom: 32px;
  }

  @media (max-width:599px) {
    .hufnUu {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }

  data-styled.g321[id="sc-fpyFWH"] {
    content: "hufnUu,"
  }

  .jWIpml {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: wrap;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
  }

  data-styled.g323[id="sc-hRnpUl"] {
    content: "jWIpml,"
  }

  .dMzqbu {
    -webkit-flex-basis: calc(16.66% - 32px);
    -ms-flex-preferred-size: calc(16.66% - 32px);
    flex-basis: calc(16.66% - 32px);
    padding: 0 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 16px;
    cursor:  default;
  }


  .dMzqbu img {
    width: 100%;
  }

  @media (min-width:600px) and (max-width:959px) {
    .dMzqbu {
      -webkit-flex-basis: calc(25% - 32px);
      -ms-flex-preferred-size: calc(25% - 32px);
      flex-basis: calc(25% - 32px);
    }
  }

  @media (max-width:599px) {
    .dMzqbu {
      -webkit-flex-basis: calc(50% - 32px);
      -ms-flex-preferred-size: calc(50% - 32px);
      flex-basis: calc(50% - 32px);
    }
  }

  data-styled.g324[id="sc-hCwLRM"] {
    content: "dMzqbu,"
  }

  .fcYBqO {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 66.66%;
  }

  data-styled.g325[id="sc-eEvSnX"] {
    content: "fcYBqO,"
  }

  .bZyufI {
    max-height: 72px;
    max-width: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (max-width:599px) {
    .bZyufI {
      max-height: 61px;
      max-width: none;
    }
  }

  .ftGILD {
    max-height: none;
    max-width: 94px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (max-width:599px) {
    .ftGILD {
      max-height: none;
      max-width: 80px;
    }
  }

  .hyvDg {
    max-height: none;
    max-width: 106px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (max-width:599px) {
    .hyvDg {
      max-height: none;
      max-width: 90px;
    }
  }

  .fmFwMV {
    max-height: none;
    max-width: 68px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (max-width:599px) {
    .fmFwMV {
      max-height: none;
      max-width: 58px;
    }
  }

  .brIhhI {
    max-height: none;
    max-width: 114px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (max-width:599px) {
    .brIhhI {
      max-height: none;
      max-width: 97px;
    }
  }

  .ctJrjn {
    max-height: none;
    max-width: 104px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (max-width:599px) {
    .ctJrjn {
      max-height: none;
      max-width: 88px;
    }
  }

  .cByaVA {
    max-height: none;
    max-width: 72px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (max-width:599px) {
    .cByaVA {
      max-height: none;
      max-width: 61px;
    }
  }

  .ePfizZ {
    max-height: none;
    max-width: 130px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (max-width:599px) {
    .ePfizZ {
      max-height: none;
      max-width: 111px;
    }
  }

  .jpJoUE {
    max-height: none;
    max-width: 109px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (max-width:599px) {
    .jpJoUE {
      max-height: none;
      max-width: 93px;
    }
  }

  .dxaCvv {
    max-height: 68px;
    max-width: 101px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (max-width:599px) {
    .dxaCvv {
      max-height: 58px;
      max-width: 86px;
    }
  }

  .cpAzpl {
    max-height: none;
    max-width: 126px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  @media (max-width:599px) {
    .cpAzpl {
      max-height: none;
      max-width: 107px;
    }
  }

  data-styled.g326[id="sc-epFoly"] {
    content: "bZyufI,ftGILD,hyvDg,fmFwMV,brIhhI,ctJrjn,cByaVA,ePfizZ,jpJoUE,dxaCvv,cpAzpl,"
  }

  .eATiML {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: wrap;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: 240px;
  }

  @media (max-width:1240px) {
    .eATiML {
      max-height: 360px;
    }
  }

  @media (min-width:600px) and (max-width:959px) {
    .eATiML {
      max-height: 480px;
    }
  }

  @media (max-width:599px) {
    .eATiML {
      max-height: 720px;
    }
  }

  @media (max-width:398px) {
    .eATiML {
      display: grid;
      max-height: 100%;
    }
  }

  data-styled.g327[id="sc-hgkClB"] {
    content: "eATiML,"
  }

  .kudaQM {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-bottom: 16px;
    cursor: pointer;
    margin-right: 16px;
  }

  data-styled.g328[id="sc-gRtYjc"] {
    content: "kudaQM,"
  }

  .hiaxnI {
    padding-bottom: 3rem;
  }

  @media (max-width:599px) {
    .hiaxnI {
      text-align: center;
    }
  }

  data-styled.g329[id="sc-gLEhor"] {
    content: "hiaxnI,"
  }

  .gTistv {
    padding-bottom: 2rem;
  }

  data-styled.g330[id="sc-gwNDlS"] {
    content: "gTistv,"
  }

  .KSGlD {
    padding: 64px 0px;
    background: #f5f5f5;
    text-align: center;
    overflow: hidden;
  }

  @media (min-width:600px) and (max-width:959px) {
    .KSGlD {
      padding: 64px 24px;
    }
  }

  @media (max-width:599px) {
    .KSGlD {
      padding: 64px 16px;
    }
  }

  data-styled.g458[id="sc-ieCETs"] {
    content: "KSGlD,"
  }

  .dFHHUD {
    text-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: auto;
  }

  data-styled.g459[id="sc-gWDJhD"] {
    content: "dFHHUD,"
  }

  .jrAHEZ {
    text-align: center;
  }

  data-styled.g460[id="sc-deghWO"] {
    content: "jrAHEZ,"
  }

  .jPAhIS {
    padding-top: 8px;
  }

  data-styled.g461[id="sc-fgyOaY"] {
    content: "jPAhIS,"
  }

  .eonzjN {
    font-weight: 600;
  }

  data-styled.g462[id="sc-gcVIGZ"] {
    content: "eonzjN,"
  }

  .jbcdYr {
    max-width: 640px;
    margin: auto;
    padding-top: 32px;
    padding-bottom: 18px;
  }

  @media (max-width:599px) {
    .jbcdYr {
      width: 100%;
    }
  }

  @media (min-width:600px) and (max-width:959px) {
    .jbcdYr {
      width: 100%;
    }
  }

  data-styled.g463[id="sc-dTWLky"] {
    content: "jbcdYr,"
  }

  .caWvZn {
    padding-top: 16px;
  }

  data-styled.g464[id="sc-eQtFQz"] {
    content: "caWvZn,"
  }

  .kZZfKW {
    padding: 4px;
    background: transparent;
    height: 24px;
    text-transform: none;
    margin-left: 4px;
    min-height: 30px;
    cursor: pointer;
  }

  .kZZfKW:hover {
    background: none;
  }

  data-styled.g466[id="sc-imVSVl"] {
    content: "kZZfKW,"
  }

  .qXgtJ {
    max-width: 864px;
    width: 100%;
    margin: auto;
    padding-top: 50px;
  }

  @media (max-width:599px) {
    .qXgtJ {
      margin: 0;
      width: 100%;
    }
  }

  data-styled.g467[id="sc-gdWVyK"] {
    content: "qXgtJ,"
  }

  .rfNuH {
    padding-left: 19%;
    margin-bottom: -12%;
  }

  @media (min-width:600px) and (max-width:959px) {
    .rfNuH {
      padding-left: 21%;
      margin-bottom: -13%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  @media (max-width:599px) {
    .rfNuH {
      padding-left: 29%;
      text-align: left;
      margin-bottom: -20%;
      text-align: left;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    
  }

  data-styled.g468[id="sc-hatQeL"] {
    content: "rfNuH,"
  }

  .julmWg {
    padding-left: 32px;
  }

  data-styled.g469[id="sc-gCEpsI"] {
    content: "julmWg,"
  }

  .hTvoYE {
    margin-right: 8px;
  }

  data-styled.g470[id="sc-hzbjYJ"] {
    content: "hTvoYE,"
  }

  .kIqaqE {
    width: 100%;
  }

  @media (max-width:599px) {
    .kIqaqE {
      width: 140%;
      margin-left: -21px;
    }
  }

  data-styled.g471[id="sc-LEJYB"] {
    content: "kIqaqE,"
  }

  .imglKR {
    background-color: #FFFFFF;
    padding: 24px;
  }

  @media (min-width:960px) {
    .imglKR {
      padding: 32px;
    }
  }

  data-styled.g472[id="sc-iiCSmI"] {
    content: "imglKR,"
  }

  .iLeacB {
    display: block;
    color: #999DA3;
  }

  data-styled.g473[id="sc-kOyqGX"] {
    content: "iLeacB,"
  }

  .laPHdg {
    display: block;
  }

  data-styled.g474[id="sc-gWaPwi"] {
    content: "laPHdg,"
  }

  .bMHMUb {
    white-space: nowrap;
    color: #941b1c;
  }

  data-styled.g475[id="sc-cVEUmN"] {
    content: "bMHMUb,"
  }

.jss219 {
    color: rgb(255, 255, 255);
    letter-spacing: 1.25px;
    text-align: center;
}

.swiper-slide {
  position: relative;
  height: 100%;
}


.swiper-slide .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, 0%);
  text-align: right;
  vertical-align: middle;
  margin: auto;
  z-index: 2;
  padding: 11px 1em;
  font-size: 3em;
  color: #131313;
  font-family: 'Confiauto Sans';
  background: rgba(255,255,255,0.6);
}

.swiper-slide .img-movil {
  display: none;
}

@media (max-width:599px) {
  .swiper-slide .title {
    font-size: 1.5em;
  }

  .swiper-slide .img-movil {
    display: block;
  }

  .swiper-slide .img-desktop {
    display: none;
  }
}


.similars.compare-enabled {
  padding: 2em!important;
}


.css-7e0s21-MuiPaper-root-MuiAppBar-root {
  background-color: #161616!important;
}


@media (min-width: 600px) and (max-width: 959px) {
  .swiper-slide .title {
    font-size: 2em;
  }

  .swiper-slide .img-swipper {
    max-width: none;
  }
  
}
